:root {
  --primary: #05445e;
  --secondary: #29539b;
  ---other: #189ab4;
  ---red: #e00025;
  ---white: #ffffff;
  ---black: #383838;
  ---blackSecondary: #6b6b6b;
}

/********* Hero Section ********/
.hero_head {
  font-family: Inter;
  font-size: 2rem;
  color: var(---black);
  line-height: 4rem;
}
.Algo_hero_ts {
  font-family: poppins;
  font-weight: 600;
}
.Awesome_hero_ts {
  font-family: poppins;
  font-weight: 600;
  color: var(--secondary);
}
.hero_sub_text {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 17px;
  color: var(---blackSecondary);
  line-height: 2.2rem;
}
.btn_join_now {
  background-color: var(--primary);
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}
.btn_join_now:hover {
  background-color: #086891;
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}
.Watch_btn {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary);
  text-decoration: none;
}
.highlight {
  display: inline-block;
  height: inherit;
  background-image: url("../../../public/img/Home-Page/watch-video-underline.svg");
  background-position: bottom;
  background-repeat: no-repeat;
}

.hero_right_bg {
  background-image: url("../../../public/img/Home-Page/hero-bg2.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

/******** statistics ********/
.statistics_bg {
  background-color: #f6f8fc;
}
.stats_ts {
  font-family: Rubik;
  color: var(--primary);
  font-size: 1.6rem;
  font-weight: 500;
}
.border_right {
  border-left: 5px solid rgb(214, 214, 214);
}

.sub_text {
  font-family: poppins;
  font-size: 1rem;
  font-weight: 500;
  color: var(---blackSecondary);
}

.solve_prob_head_ts {
  font-family: Mulish;
  font-weight: 400;
  color: var(---black);
  font-size: 2.2em;
}

.solve_prob_span {
  font-weight: 800;
  color: var(--primary);
}

.problems_card_mod {
  border-top: 5px solid #067d5c !important;
  min-height: 430px;
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.inner_card_mod {
  background-color: #f5f6f6;
  border-radius: 30px;
  border: 0px;
  height: 200px;
}
.card_head {
  font-family: Rubik;
  font-size: 1.3rem;
  color: var(--secondary) !important;
  font-weight: 500;
}

.card_text {
  font-family: DM Sans;
  font-weight: 500;
  color: var(---blackSecondary);
  font-size: 1rem;
}

.card_quote_mod {
  background-color: #f6f9f2;
  border: 0px;
  border-radius: 20px;
  background-image: url("../../../public/img/Home-Page/quotes.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.quote_ts {
  font-family: Mulish;
  font-weight: 700;
  color: var(--secondary);
  font-size: 1.9rem;
}

.inner_quote_ts {
  font-family: Caveat;
  font-weight: 500 !important;
}

.quote_sub_text {
  font-family: poppins;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--secondary);
}

.sliding_text {
  font-family: Mulish;
  font-weight: 600;
  font-size: 2.1em;
  color: var(--primary);
}

.sliding_text_mod {
  color: var(---red);
}

.course_bg {
  background-color: #fff9ef;
}

.course_head {
  font-family: Mulish;
  font-weight: 700;
  color: var(--primary);
  font-size: 1.8rem;
}

.course_subhead {
  font-family: DM Sans;
  font-size: 1.1rem;
  color: var(--secondary);
  font-weight: 500;
  line-height: 30px;
}

.card_mod_features {
  border: 0px;
  background-color: #fbf8ff;
}

.card_head_features {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--secondary);
}
.card_subhead_features {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--primary);
  margin-top: -10px;
}

.card_text_features {
  font-family: DM Sans;
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 400;
}

.card_mod_features_intern {
  border: 0px;
  background-color: #fef7f7;
}

.card_mod_features_placement {
  border: 0px;
  background-color: #fefcf0;
}

.features_ts_bottom {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--primary);
}

.btn_checkout {
  background-color: var(---red);
  font-family: Mulish;
  font-size: 1.1rem;
  font-weight: 700;
  color: var(---white);
}

.btn_checkout:hover {
  background-color: #f32447;
  color: var(---white);
}

.mock_bg {
  background-color: #f8f5ff;
}

.mock_promo_ts {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(---red);
}

.form_bg {
  background-color: #05435e0e;
}

.newletter_head {
  font-family: DM Sans;
  font-weight: 600;
  font-size: 1.6rem;
  color: #05445e;
}

.newletter_innerhead {
  font-family: DM Sans;
  font-weight: 600;
  font-size: 1.6rem;
  color: #3b75da;
}

.newletter_subtext {
  font-family: Mulish;
  font-size: 1.1rem;
  font-weight: 600;
  color: #6b6b6b;
}

.newsletter_card_mod {
  border: 0px;
  background-color: #f1f6ff;
  margin-left: -100px;
}

.btn_newsletter {
  background-color: var(---red);
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 700;
  color: var(---white);
}

.btn_newsletter:hover {
  background-color: #f32447;
  color: var(---white);
}

.cal_img {
  position: relative;
  z-index: 1 !important;
}

/* Recent Highlight */
.view_course_button {
  background: rgb(255, 188, 32);
  color: white !important;
  font-weight: 500;
  font-size: 1rem;
  height: fit-content;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-bottom: 3px solid rgb(255, 213, 116) !important;
}

.view_course_button:hover {
  background: rgb(227, 167, 27);
}

.start_learn_button {
  background: rgb(0, 182, 52);
  color: white !important;
  font-weight: 500;
  font-size: 1rem;
  height: fit-content;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-bottom: 3px solid rgb(0, 239, 68) !important;
}

.start_learn_button:hover {
  background: rgb(0, 142, 40);
}

.mock_test_button {
  background: rgb(1, 190, 200);
  color: white !important;
  font-weight: 500;
  font-size: 1rem;
  height: fit-content;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-bottom: 3px solid rgb(11, 243, 255) !important;
}

.mock_test_button:hover {
  background: rgb(0, 153, 161);
}
.testimonial_button {
  background: rgb(0, 106, 187);
  color: white !important;
  font-weight: 500;
  font-size: 1rem;
  height: fit-content;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-bottom: 3px solid rgb(0, 137, 242) !important;
}

.testimonial_button:hover {
  background: rgb(0, 82, 144);
}

.recent_highlight_text {
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: normal;
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.hidescrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 992px) {
  .hero_right_bg {
    background-image: none;
  }
  .hide_sm {
    display: none !important;
  }
  .border_right {
    border-left: none;
  }
  .newsletter_card_mod {
    background-color: transparent !important;
    margin-left: -0px;
  }

  .newletter_head {
    font-size: 1.8rem;
  }
}

@media (min-width: 992px) {
  .hide_lg {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .recent_button_container {
    min-width: auto !important;
  }
}
