:root {
  --primary: #05445e;
  --secondary: #29539b;
  ---other: #189ab4;
  ---red: #f13453;
  ---white: #ffffff;
  ---black: #383838;
  ---blackSecondary: #6b6b6b;
}

.navbar_ts {
  font-family: Rubik;
  font-size: 1rem;
  color: var(---blackSecondary);
  font-weight: 500;
}

.navbar_ts .active {
  font-family: Rubik;
  color: var(--primary) !important;
  font-weight: 500;
  border-bottom: 1px solid var(---other);
}

.btn_red {
  background-color: var(---red);
  font-family: Rubik;
  font-size: 14px;
  color: var(---white);
}

.btn_red:hover {
  background-color: #e21134;
  color: var(---white);
}

.btn_bordered {
  border: 2px solid var(---other);
  font-family: Rubik;
  font-size: 14px;
  color: var(--primary);
}

.btn_bordered:hover {
  background-color: var(---other);
  font-family: Rubik;
  font-size: 14px;
  color: var(---white);
}
