.Ad_bar_ts {
  font-family: Mulish;
  font-weight: 600;
  font-size: 1.2rem;
}
.red {
  color: #05445e !important;
}
.emoji {
  font-size: 1.4rem;
}
.enroll_btn {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 12px;
}
