.contact_head{
    font-family: DM Sans;
    font-weight: 500;
    font-size: 1.6rem;
    color: #124275;
}

.bottom{
    padding-bottom: 5px;
    border-bottom: 2px solid #086891 ;
}

.label_ts{
    font-family: inter;
    font-size: 1.1rem;
    color: #5e5e5e;
    font-weight: 500;
    letter-spacing: 1px;
}

.submit_btn{
    font-family: DM Sans;
    background-color: #05445E;
    color: white;
    font-size: 1.2rem;
}

.submit_btn:hover{
    background-color: #08587a;
    color: white;
}